.upload-box {
    height: max-content;
    min-height: 200px;
    width: 200px;
    /* max-width was added in order for the image div to not overflow the Col element */
    max-width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    z-index: 2;
    overflow: hidden;
}

.upload-box-list {
    max-height: 100px;
    min-height: 100px;
    width: 100px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    z-index: 2;
    overflow: hidden;
}

.upload-box-small {
    max-height: 100px;
    min-height: 100px;
    width: 100px;
    cursor: pointer;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    z-index: 2;
    overflow: hidden;
}

/* .upload-icon:before {
    margin-left: 37%;
} */

.upload-box-img {
    max-width: 100%;
    min-width: 100%;
}

.upload-box-img:hover {
    cursor: pointer;
}

/* .upload-box-img, i:hover{

} */

.icon-div {
    position: absolute;
    top: 0%;
    height: 100%;
    width: 100%;
    z-index: 3;
}

.icon-div-list {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    z-index: 3;
}

.icon-div-list:not(.nohover):hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.icon-div:not(.nohover):hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.image-icons:not(.nohover):hover {
    opacity: 0.65;
    cursor: pointer;
}

.upload-box {
    position: relative;
}

.upload-box:hover {
    cursor: pointer;
}

.upload-box-img-div * {
    pointer-events: auto;
}
