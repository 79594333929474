/* toastify classes */

:root {
    --toastify-color-info: #39afd1 !important;
    --toastify-color-success: #0acf97 !important;
    --toastify-color-warning: #ffbc00 !important;
    --toastify-color-error: #fa5c7c !important;
}

.Toastify__toast-container--top-right {
    top: 5em !important;
}

.json-btn {
    position: relative;
    top: -8px;
}

.json-icon:hover {
    color: #464c51 !important;
    cursor: pointer;
}

.tooltip-inner {
    max-width: max-content !important;
}

.disable-table-hover {
    background-color: unset !important;
}

.table-hover tr:not(.disable-table-hover) {
    cursor: pointer;
}

.search-bar {
    border: 5px solid #ccd0fb !important;
}

/* circle-buttons */
.btn-circle {
    width: 45px !important;
    height: 45px !important;
    line-height: 45px !important;
    text-align: center !important;
    padding: 0 !important;
    border-radius: 50% !important;
}

.btn-circle i {
    position: relative;
    /* top: -1px; */
}

.btn-circle-sm {
    width: 26px !important;
    height: 26px !important;
    line-height: 30px !important;
    font-size: 0.9rem !important;
}

.btn-circle-md {
    width: 33px !important;
    height: 33px !important;
    line-height: 30px !important;
    font-size: 0.9rem !important;
}

.btn-circle-xs {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    font-size: 0.9rem !important;
}

.btn-circle-lg {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.1rem;
}

.btn-circle-xl {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
}

.nav-link.active {
    background-color: rgba(255, 255, 255, 1) !important;
}

/* set height of ckeditor */
.ck-editor__editable {
    min-height: 150px !important;
}

.page-loading-loader-div {
    height: calc(100vh - 136px);
}

.sort-order-modal-body{
    max-height: calc(100vh - 250px);
    overflow: auto;
}
