.floating-button-top-div {
    position: fixed;
    right: 70px;
    bottom: 100px;
    z-index: 1000;
}

.floating-button-div {
    position: relative;
}

.floating-save-button {
    justify-content: space-between !important;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 60px !important;
    transition: 1s !important;
}

.floating-save-button:hover {
    justify-content: space-between !important;
    height: 40px;
    width: 110px;
    transform: translate(-50%, 10px);
    border-radius: 5px !important;
    transition: 1s !important;
}

.floating-save-button-icon-div {
    transition: transform 1s !important;
}

.floating-save-button:hover > .floating-save-button-icon-div {
    transition: transform 1s !important;
    transform: rotate(1turn) !important;
    float: left;
}

.floating-button-span {
    display: none;
    font-size: 18px;
    transition: 1s !important;
}

.floating-save-button:hover > .floating-button-span {
    display: block;
    transition: 1s !important;
    float: right;
    overflow: hidden;
}

.floating-save-button-icon {
    width: 30px;
    height: 30px;
}

/* .floating-save-button-icon{
} */

.floating-save-button-icon-div:hover {
    transition: transform 1s !important;
    transform: rotate(1turn) !important;
}
